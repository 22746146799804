import React from "react"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Heading from "src/components/Heading"

import {
  ArrivalInput,
  Availability,
  DepartureInput,
  ElectricSelector,
  PriceEstimate,
  SpecialRequestInput,
  StepContent,
  StepWrapper,
  StorageProductSelector,
} from "./shared"

const SummaryStep = () => {
  return (
    <StepWrapper testId="trw-summary-step">
      <div className="flex w-full flex-col space-y-4 lg:flex-3">
        <StepContent>
          <Heading.PageTitle>Itinerary</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <ArrivalInput />
              <DepartureInput />
              <StorageProductSelector />
              <ElectricSelector />
              <div className="lg:col-span-2">
                <SpecialRequestInput />
              </div>
            </div>
          </div>
          <Divider />
          <Heading.PageTitle>Payment</Heading.PageTitle>
          <div className="mt-5">TODO: payment fields</div>
          <Divider />
          <Heading.PageTitle>Discounts and Coupon Codes</Heading.PageTitle>
          <div className="mt-5">TODO: discount and coupon code fields</div>
          <Divider />
          <Heading.PageTitle>Rates</Heading.PageTitle>
          <div className="mt-5">TODO: rate fields</div>
        </StepContent>

        <StepContent>
          <Heading.PageTitle>Availability</Heading.PageTitle>
          <Availability />
        </StepContent>
      </div>
      <PriceEstimate>
        <Button variant="primary" type="submit">
          Create Reservation
        </Button>
      </PriceEstimate>
    </StepWrapper>
  )
}

export default SummaryStep
