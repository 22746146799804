import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"

const StorageProductSelector = () => {
  const { storageProducts } = useContext(WizardContext)
  const { register } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="trw-storage-product">Type</Form.Label>
      <Form.Select id="trw-storage-product" {...register("storage_product_id")}>
        {storageProducts.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

export default StorageProductSelector
