export const STEPS = {
  availability: 1,
  contact: 2,
  summary: 3,
}

export const MONTHLY_BILLING_SCHEDULE = "monthly"

export const ELECTRIC_MONTHLY_BILLING_STRUCTURE = "per_month"
export const NO_ELECTRIC_CHOSEN_VALUE = "none"
