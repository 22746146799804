import PropTypes from "prop-types"
import React from "react"

const PriceEstimate = ({ children }) => {
  return (
    <div
      data-testid="trw-price-estimate"
      className="w-full self-start rounded border bg-white p-5 lg:flex-1"
    >
      <div className="flex flex-col space-y-4">{children}</div>
    </div>
  )
}

PriceEstimate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PriceEstimate
