import classNames from "classnames"
import React, { useContext } from "react"

import { WizardContext } from "./WizardContextProvider"
import { STEPS } from "./constants"

export const STEP_CLASSES = {
  previousStep: "border-blue-300 bg-blue-100 text-blue-700",
  currentStep: "cursor-default border-blue-500 text-blue-700",
  futureStep: "border-gray-300",
  enabledStep:
    "cursor-pointer hover:border-teal-500 hover:bg-transparent hover:text-teal-700",
  disabledStep: "cursor-not-allowed",
}

const ReservationStepSelector = () => {
  const { currentStep, stepEnabled, goToStep } = useContext(WizardContext)

  const renderStep = (step) => {
    const stepNumber = STEPS[step]
    const stepIsEnabled = stepEnabled(stepNumber)
    const isCurrentStep = currentStep === stepNumber
    return (
      <button
        key={stepNumber}
        onClick={() => goToStep(stepNumber)}
        disabled={!stepIsEnabled}
        className={classNames(
          "h4 m-0 flex size-10 items-center justify-center rounded-full border-2 font-semibold",
          {
            [STEP_CLASSES.previousStep]: stepNumber < currentStep,
            [STEP_CLASSES.currentStep]: isCurrentStep,
            [STEP_CLASSES.futureStep]: stepNumber > currentStep,
            [STEP_CLASSES.enabledStep]: stepIsEnabled && !isCurrentStep,
            [STEP_CLASSES.disabledStep]: !stepIsEnabled,
          }
        )}
      >
        {stepNumber}
      </button>
    )
  }

  return (
    <div
      data-testid="reservation-step-selector"
      className="mb-8 mt-4 flex items-center justify-center space-x-16"
    >
      {Object.keys(STEPS).map(renderStep)}
    </div>
  )
}

export default ReservationStepSelector
