import Decimal from "decimal.js"
import * as yup from "yup"

export const contactBoatSchema = yup
  .object()
  .shape({
    name: yup.string().required("Boat name is required."),
    boat_type: yup.string().required("Boat type is required."),
    length_overall: yup
      .string()
      .required("Length is required.")
      .test(
        "testing contact_boat.length_overall",
        "Length is invalid.",
        (lengthOverall, context) => {
          if (lengthOverall && new Decimal(lengthOverall).lte(0)) {
            return context.createError({
              message: "Length must be greater than 0.",
              path: "contact_boat.length_overall",
            })
          }
          return true
        }
      ),
    beam: yup
      .string()
      .required("Beam is required.")
      .test(
        "testing contact_boat.beam",
        "Beam is invalid.",
        (beam, context) => {
          if (beam && new Decimal(beam).lte(0)) {
            return context.createError({
              message: "Beam must be greater than 0.",
              path: "contact_boat.beam",
            })
          }
          return true
        }
      ),
    height: yup
      .string()
      .test(
        "testing contact_boat.height",
        "Height is invalid.",
        (height, context) => {
          if (height && new Decimal(height).lte(0)) {
            return context.createError({
              message: "Height must be greater than 0.",
              path: "contact_boat.height",
            })
          }
          return true
        }
      ),
    draw: yup
      .string()
      .test(
        "testing contact_boat.draw",
        "Draft is invalid.",
        (draw, context) => {
          if (draw && new Decimal(draw).lte(0)) {
            return context.createError({
              message: "Draft must be greater than 0.",
              path: "contact_boat.draw",
            })
          }
          return true
        }
      ),
  })
  .required()

export const contactSchema = yup
  .object()
  .shape({
    name: yup.string().required("Contact name is required."),
    email: yup.string().required("Email is required."),
  })
  .required()
