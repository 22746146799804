import { mutateApi } from "src/utils/api/ApiMethods"

export const createTransientReservation = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations`,
    method: "POST",
    data: params,
  })
}

export const calculatePrice = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations/calculate_price`,
    method: "POST",
    data: params,
  })
}

// Note: 'get' is misleading here, as this is a POST request.
// But, POSTing to this endpoint does not create any resources; it is functionally a GET.
// A good future task would be to update this endpoint to be a GET request.
export const getAvailability = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservations/availability_v2`,
    method: "POST",
    data: params,
  })
}
