import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import DiscountTable from "src/main/Contracts/ContractsForm/shared/DiscountTable"
import { parseSavedDiscount } from "src/main/Contracts/ContractsForm/tabPanels/Rate/utils"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { replaceProductSale } from "src/api/Billing/Items"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PriceChangeSection from "../shared/PriceChangeSection"
import PriceDisplay from "../shared/PriceDisplay"
import { useCalculateDiscountAmounts } from "./hooks"

const EditInstallmentDiscountsModal = ({
  installmentProductSaleTxn,
  onClose,
  paymentMethods,
  reservationId,
  contractQuoteId,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const storageSaleRemoved =
    installmentProductSaleTxn.amount !== 0 &&
    installmentProductSaleTxn.net_amount === 0
  const storageSaleRemovedHelpText =
    "Discounts cannot be managed — boat storage item has been removed."

  const savedDiscounts = storageSaleRemoved
    ? []
    : installmentProductSaleTxn.product_sale.product_sale_discounts
  const defaultPaymentMethodId = paymentMethods.default?.id ?? "manual"
  const txnId = installmentProductSaleTxn.id

  const productSaleOriginalAmount =
    installmentProductSaleTxn.product_sale.original_amount
  const productSaleTaxRate = new Decimal(
    installmentProductSaleTxn.product_sale.tax_rate ?? 0
  ).toNumber()

  // https://wanderlustgroup.atlassian.net/browse/TB-1306
  // const checkboxRef = useRef()
  // const [shouldSendUpdateToBoater, setSendUpdateToBoater] = useState(false)

  const methods = useForm({
    defaultValues: {
      paymentMethodId: defaultPaymentMethodId,
      distributionOption: "custom_due_date",
      feesAndDiscounts: savedDiscounts.map(parseSavedDiscount),
      dueDate: new Date(),
    },
  })

  const originalDiscountTotal = savedDiscounts.reduce((sum, discount) => {
    return new Decimal(discount.amount).add(sum).toNumber()
  }, 0)

  const discounts = methods.watch("feesAndDiscounts")

  const { newDiscountTotal, newTotal, newTaxAmount, discountsWithAmount } =
    useCalculateDiscountAmounts({
      currentDiscounts: discounts,
      productSaleOriginalAmount,
      productSaleTaxRate,
    })

  const discountAmountChanged = !new Decimal(newDiscountTotal).eq(
    originalDiscountTotal
  )

  const { mutate, isLoading, isSuccess } = useMutation({
    queryKey: ["editDiscounts", txnId],
    mutationFn: (data) => replaceProductSale({ productSaleTxnId: txnId, data }),
    onSuccess: () => onClose({ shouldRefresh: true }),
    onError: () => {
      methods.setError("root.serverError", {
        message: "Something went wrong! Please contact support.",
      })
    },
  })

  const onSubmit = (data) => {
    const {
      distributionOption,
      dueDate,
      paymentMethodId,
      refundPaymentMethodId,
    } = data
    const {
      invoices: _invoices,
      product_sale: productSale,
      ...rest
    } = installmentProductSaleTxn

    const {
      product_sale_discounts: _discounts,
      night_unit_prices: nightUnitPricesAttributes,
      ...productSaleRest
    } = productSale

    const isScheduled = distributionOption === "custom_due_date"

    const params = {
      manage_id: marinaSlug,
      boater_notification: false, // https://wanderlustgroup.atlassian.net/browse/TB-1306
      due_date: isScheduled ? dueDate : null,
      payment_method_id: !isScheduled
        ? null
        : refundPaymentMethodId || paymentMethodId,
      distribution_option: !isScheduled
        ? distributionOption
        : "reduce_late_to_early",
      txn: {
        ...rest,
        reservation_sale: true,
        amount: newTotal,
        net_amount: newTotal,
        invoiced_amount: newTotal,
        tax_amount: newTaxAmount,
        product_sale_attributes: {
          ...productSaleRest,
          tax_amount: newTaxAmount,
          product_sale_discounts_attributes: discountsWithAmount,
          night_unit_prices_attributes: nightUnitPricesAttributes,
        },
      },
    }
    mutate(params)
  }

  const feesAndDiscountsChanged =
    JSON.stringify(discounts) !==
    JSON.stringify(methods.formState.defaultValues.feesAndDiscounts)

  const originalValueDisplay = (
    <span className="text-2xl font-semibold">
      {formattedCentsToDollars(installmentProductSaleTxn.amount)}
    </span>
  )
  const newValueDisplay = (
    <span className="text-2xl font-semibold">
      {formattedCentsToDollars(newTotal)}
    </span>
  )

  const amountPaid = new Decimal(installmentProductSaleTxn.amount)
    .minus(installmentProductSaleTxn.balance)
    .toNumber()
  const isRefundDue = new Decimal(amountPaid).gt(newTotal)

  return (
    <Modal
      isOpen
      onClose={() => onClose({ shouldRefresh: false })}
      maxSize="medium"
    >
      <FormProvider {...methods}>
        <Form autocomplete={false}>
          <Modal.Header title="Edit discount" />
          <Modal.Body>
            <PriceDisplay
              primaryTitle={false}
              originalValueDisplay={
                storageSaleRemoved ? "-" : originalValueDisplay
              }
              newValueDisplay={storageSaleRemoved ? "-" : newValueDisplay}
            />
            <Divider />
            <DiscountTable
              canEdit={false}
              featureDisabled={storageSaleRemoved}
              hidePlaceholders
            />
            {storageSaleRemoved && (
              <div className="flex flex-row items-center space-x-2 py-4">
                <i className="icon icon-info-circle text-lg text-yellow-700" />
                <span>{storageSaleRemovedHelpText}</span>
              </div>
            )}
            {discountAmountChanged ? (
              <PriceChangeSection
                contractQuoteId={contractQuoteId}
                isRefundDue={isRefundDue}
                reservationId={reservationId}
                paymentMethods={paymentMethods}
                priceDifference={new Decimal(newTotal)
                  .minus(installmentProductSaleTxn.amount)
                  .toNumber()}
              />
            ) : null}
            {/* https://wanderlustgroup.atlassian.net/browse/TB-1306 */}
            {/* <Divider />
            <Form.Checkbox
              name="send-update-to-boater"
              ref={checkboxRef}
              onChange={() => {
                setSendUpdateToBoater(!shouldSendUpdateToBoater)
              }}
              checked={shouldSendUpdateToBoater}
              compact
              label="Send email update to boater"
            /> */}
            {methods.formState.errors?.root?.serverError ? (
              <div className="flex w-full justify-end">
                <Form.Error>
                  {methods.formState.errors.root.serverError.message}
                </Form.Error>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button
                variant="tertiary"
                onClick={() => onClose({ shouldRefresh: false })}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                disabled={isLoading || isSuccess || storageSaleRemoved}
                variant="primary"
                type="submit"
                onClick={
                  !feesAndDiscountsChanged
                    ? () => onClose({ shouldRefresh: false })
                    : methods.handleSubmit(onSubmit)
                }
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  )
}

EditInstallmentDiscountsModal.propTypes = {
  installmentProductSaleTxn: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default EditInstallmentDiscountsModal
