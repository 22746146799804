import { validateEndDate } from "src/main/Billing/Items/validators"
import * as yup from "yup"

import {
  contactBoatSchema,
  contactSchema,
} from "src/components/ContactSearch/contactAndBoatSchema"

export const schema = yup
  .object()
  .shape({
    arrival: yup.date().required("Arrival is required."),
    departure: yup
      .date()
      .required("Departure is required.")
      .test(
        "testing valid departure date",
        "Departure date is invalid",
        (departure, context) => {
          const invalid = validateEndDate({
            end: departure,
            start: context.parent?.arrival,
          })
          if (invalid) {
            return context.createError({
              message: "Must be the same as or later than arrival date.",
              path: "departure",
            })
          }
          return true
        }
      ),
    billing_schedule_id: yup.string().required("Billing schedule is required."),
    storage_product_id: yup.string().required("Storage type is required."),
    electric_product_id: yup.string(),
    contact_boat_id: yup.string().required("Boat is required."),
    contact_boat: yup.object().when("contact_boat_id", {
      is: (contactBoatId) => contactBoatId === "addNew",
      then: () => contactBoatSchema,
    }),
    contact_id: yup.string().required("Contact is required."),
    contact: yup.object().when("contact_id", {
      is: (contactId) => contactId === "addNew",
      then: () => contactSchema,
    }),
  })
  .required()
