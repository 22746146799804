import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import CancelInquiryModal from "src/main/Account/Inquiries/CancelInquiryModal"
import Chat from "src/main/Chat/Chat"
import Header from "src/main/Chat/Header"
import ChatSidebar from "src/main/Chat/Sidebar"
import { useUpdateInquiryStatus } from "src/main/Chat/chat_object_helpers"
import AcceptToContractGroupModal from "src/main/Inquiries/AcceptToContractGroupModal"
import DeclineModal from "src/main/Inquiries/DeclineModal"
import CancelWaitlistEntryModal from "src/main/LongTermWaitlist/boater/CancelWaitlistEntryModal"

import { queryChatObject } from "src/api/Chat"

import useWindowSize from "src/hooks/use_window_size"

export const ChatContext = createContext(null)

const ChatContainer = ({ chatObject, viewAs, tracking = {} }) => {
  const { isLargeScreen } = useWindowSize()
  const [showSidebar, setShowSidebar] = useState(isLargeScreen)
  const [showCancelWaitlistEntryModal, setShowCancelWaitlistEntryModal] =
    useState(false)
  const [showCancelInquiryModal, setShowCancelInquiryModal] = useState(false)
  const [showAcceptToContractModal, setShowAcceptToContractModal] =
    useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const { updateStatus, updatingInquiryStatus } = useUpdateInquiryStatus({
    setShowDeclineModal,
  })
  const queryClient = useQueryClient()

  const determineHeight = () => {
    if (viewAs === "boater") {
      return isLargeScreen ? "calc(100vh - 260px)" : "calc(100vh - 194px)"
    } else {
      return isLargeScreen ? "calc(100vh - 131px)" : "calc(100vh - 224px)"
    }
  }

  const { data: chatObjectData } = useQuery(
    [chatObject.type, chatObject.id],
    () => queryChatObject({ id: chatObject.id, type: chatObject.type, viewAs }),
    {
      initialData: chatObject,
      refetchOnMount: false,
    }
  )

  return (
    <ChatContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        chatObjectData,
        viewAs,
        tracking,
        setShowAcceptToContractModal,
        setShowDeclineModal,
        setShowCancelWaitlistEntryModal,
        setShowCancelInquiryModal,
        updateStatus,
      }}
    >
      <div className="-mt-5 flex flex-col overflow-hidden">
        <Header
          actions={chatObjectData.header.actions || []}
          details={chatObjectData.header.details}
        />
        <div
          className="flex"
          style={{
            height: determineHeight(),
          }}
        >
          <div className="flex-1 border-r">
            <Chat chatObject={chatObjectData} />
          </div>
          <div className="overflow-y-auto overflow-x-hidden bg-white">
            <ChatSidebar items={chatObjectData.sidebar} />
          </div>
        </div>
      </div>
      {chatObjectData.groups && chatObjectData.groups.length > 0 && (
        <AcceptToContractGroupModal
          contractGroups={chatObjectData.groups}
          isOpen={showAcceptToContractModal}
          selectedInquiry={{ encodedId: chatObjectData.id }}
          contractGroup={chatObjectData.contractGroupId}
          setIsOpen={setShowAcceptToContractModal}
        />
      )}
      <DeclineModal
        isOpen={showDeclineModal}
        setIsOpen={setShowDeclineModal}
        selectedContact={chatObjectData.id}
        updateStatus={updateStatus}
        isLoading={updatingInquiryStatus}
      />
      <CancelWaitlistEntryModal
        closeModal={() => {
          setShowCancelWaitlistEntryModal(false)
          queryClient.invalidateQueries(chatObject.type)
        }}
        isOpen={showCancelWaitlistEntryModal}
        entryId={chatObject.id}
        tracking={{
          submit: "long_term_waitlist_boater:cancel_entry_confirmation_pressed",
          eventProperties: {
            marina_id: chatObjectData.marinaId,
            marina_name: chatObjectData.marinaName,
            long_term_waitlist_id: chatObjectData.id,
          },
        }}
      />
      <CancelInquiryModal
        closeModal={() => {
          setShowCancelInquiryModal(false)
          queryClient.invalidateQueries(chatObject.type)
        }}
        isOpen={showCancelInquiryModal}
        inquiryId={chatObject.id}
        tracking={{
          submit: "inquiry_detail:cancel_request_executed",
          eventProperties: {
            marina_id: chatObjectData.marinaId,
            marina_name: chatObjectData.marinaName,
            inquiry_id: chatObjectData.id,
            status: "canceled",
          },
        }}
      />
    </ChatContext.Provider>
  )
}

ChatContainer.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    sidebar: PropTypes.array.isRequired,
    header: PropTypes.object.isRequired,
    groups: PropTypes.array,
    contractGroupId: PropTypes.string,
    marinaName: PropTypes.string,
    marinaId: PropTypes.string,
  }).isRequired,
  viewAs: PropTypes.oneOf(["boater", "dockmaster"]),
  tracking: PropTypes.shape({
    send: PropTypes.shape({
      event: PropTypes.string.isRequired,
      attributes: PropTypes.object,
    }),
  }),
}

export default ChatContainer
