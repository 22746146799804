import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"
import PosItemCard from "src/main/PointOfSale/FuelTransaction/PosItemCard"
import TransactionCard from "src/main/PointOfSale/FuelTransaction/TransactionCard"
import { POSContext } from "src/main/PointOfSale/Root"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import Heading from "src/components/Heading"
import Loader from "src/components/Loader"
import Modal from "src/components/Modal"

import { queryProducts } from "src/api/Billing/Items"
import {
  getRecentFuelCloudTransactions,
  markFuelCloudTransactionProcessed,
} from "src/api/PointOfSale/fuelTransactions"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const FuelTransactionPickListModal = ({ isOpen, setIsOpen, onClose }) => {
  const marinaSlug = getCurrentMarinaSlug()

  const {
    cart: { addItemToCart },
  } = useContext(POSContext)

  const [showTransactionList, setShowTransactionList] = useState(true)
  const [showPosItemList, setShowPosItemList] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState(null)

  const toggleDisplayVariables = (transaction) => {
    setSelectedTransaction(transaction)
    setShowTransactionList(!showTransactionList)
    setShowPosItemList(!showPosItemList)
  }

  const transactionData = useQuery(
    ["transactions", marinaSlug],
    () => getRecentFuelCloudTransactions({ marinaSlug }),
    {
      enabled: showTransactionList,
      refetchOnMount: true,
    }
  )

  const { mutate: markCartItemAsProcessed } = useMutation((transaction) => {
    return markFuelCloudTransactionProcessed({
      marinaSlug,
      transactionId: transaction.id,
    })
  })

  const itemsQuery = useQuery({
    queryKey: [
      "FuelTransactionListItemSearch",
      marinaSlug,
      selectedTransaction?.product_kind,
      selectedTransaction?.id,
    ],
    queryFn: () => {
      // TODO: This is a temporary fix to allow for the search to work for
      // Gasoline. We need to update the API to allow for the search to work
      // with the POS item category ie "Fuel".
      const searchTerm =
        selectedTransaction?.product_kind === "Gasoline"
          ? "Gas"
          : selectedTransaction?.product_kind
      return queryProducts({
        page: 1,
        marinaSlug,
        searchTerm,
        availability: ["point_of_sale"],
        includeChildren: true,
      })
    },
    enabled: showPosItemList,
  })

  const handleAddItemToCart = (item, selectedTransaction) => {
    addItemToCart({ item: { ...item, quantity: selectedTransaction.volume } })
    markCartItemAsProcessed(selectedTransaction)
    setShowPosItemList(false)
    setShowTransactionList(true)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Modal.Header>
        <Heading.ModalTitle>Add fuel from pump</Heading.ModalTitle>
        <div className="text-sm text-gray-600">Synced from FuelCloud</div>
      </Modal.Header>
      <Modal.Body>
        {showTransactionList && (
          <div className="space-y-3">
            {transactionData.isLoading && !transactionData.isError && (
              <Loader name="Transactions" />
            )}
            {transactionData.isError && (
              <AlertBanner type="error">
                Error getting transaction data! If this issue persists, please
                contact Dockwa Support at mayday@dockwa.com.
              </AlertBanner>
            )}
            <div className="space-y-3">
              {transactionData.data &&
                transactionData.data.length > 0 &&
                transactionData.data.map((transaction) => (
                  <TransactionCard
                    key={transaction.id}
                    transaction={transaction}
                    onClick={() => toggleDisplayVariables(transaction)}
                  />
                ))}
            </div>
          </div>
        )}
        {showPosItemList && (
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <Button
                onClick={() => toggleDisplayVariables(null)}
                variant="tertiary"
                icon="icon-md-arrow-back"
              >
                Back to transactions
              </Button>
            </div>

            <div>
              <h2 className="mb-1 text-lg font-medium text-gray-900">
                Select a POS item
              </h2>
              <p className="mb-4 text-sm text-gray-500">
                Choose the item to add {selectedTransaction?.volume} gallons of{" "}
                {selectedTransaction?.product_name}
              </p>

              <div className="space-y-3">
                {itemsQuery.isLoading && !itemsQuery.isError && (
                  <Loader name="POS items" />
                )}

                {itemsQuery.isError && (
                  <AlertBanner type="error">
                    Error loading POS items! If this issue persists, please
                    contact Dockwa support at mayday@dockwa.com.
                  </AlertBanner>
                )}

                {itemsQuery.data &&
                  itemsQuery.data.length > 0 &&
                  itemsQuery.data.map((item) => (
                    <PosItemCard
                      key={item.id}
                      item={item}
                      transaction={selectedTransaction}
                      onClick={() =>
                        handleAddItemToCart(item, selectedTransaction)
                      }
                    />
                  ))}

                {itemsQuery.data?.length === 0 && (
                  <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                    No matching POS items found. Please contact Dockwa support
                    at mayday@dockwa.com.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

FuelTransactionPickListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FuelTransactionPickListModal
