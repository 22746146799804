import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import DockwaPlusDealsTable from "src/main/DockwaPlusDeals/Manage/DockwaPlusDealsTable"
import Fuel from "src/main/DockwaPlusDeals/Manage/Fuel"

import Tabs from "src/components/Tabs"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

export const ManageDockwaPlusDealsContext = createContext(null)

const ManageDockwaPlusDeals = ({
  newPath,
  dockwaPlusDeals,
  fuelDiscountsEnabled,
  canCreateDeal,
  canUpdateDeal,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const tab = urlParams.get("tab")
  const page = urlParams.get("page")
  const [queryPage, setQueryPage] = useState(page ? parseInt(page) : 1)
  const [currentTab, setCurrentTab] = useState(tab === "fuel" ? 1 : 0)
  const tabName = (tab) => {
    if (tab === 0) {
      return "reservations"
    } else {
      return "fuel"
    }
  }

  const updateParams = (params) => {
    setQueryPage(params.page)
    setCurrentTab(params.tab)
    updateUrlQueryParams({
      tab: tabName(params.tab),
      page: params.page,
    })
  }

  const handleTabChange = (tab) => {
    updateParams({ tab: tab, page: 1 })
  }

  if (fuelDiscountsEnabled) {
    return (
      <ManageDockwaPlusDealsContext.Provider
        value={{
          currentTab,
          queryPage,
          updateParams,
          canCreateDeal,
          canUpdateDeal,
        }}
      >
        <div className="h-[75vh] bg-white px-0 pt-8 lg:px-6 lg:pt-8">
          <Tabs
            selectedIndex={currentTab}
            onChange={(tab) => handleTabChange(tab)}
          >
            <div className="flex w-full flex-col justify-between lg:flex-row">
              <div className="flex justify-center border-b lg:border-none">
                <Tabs.TabList>
                  <Tabs.Tab title="Reservations" />
                  <Tabs.Tab title="Fuel" />
                </Tabs.TabList>
              </div>
            </div>
            <hr className="mt-0" />
            <Tabs.Panels>
              <Tabs.Panel>
                <DockwaPlusDealsTable
                  newPath={newPath}
                  dockwaPlusDeals={dockwaPlusDeals}
                />
              </Tabs.Panel>
              <Tabs.Panel>
                <Fuel />
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </div>
      </ManageDockwaPlusDealsContext.Provider>
    )
  } else {
    return (
      <div className="h-[75vh] bg-white px-0 pt-8 lg:px-6 lg:pt-8">
        <DockwaPlusDealsTable
          newPath={newPath}
          dockwaPlusDeals={dockwaPlusDeals}
        />
      </div>
    )
  }
}

ManageDockwaPlusDeals.propTypes = {
  newPath: PropTypes.string.isRequired,
  dockwaPlusDeals: PropTypes.array.isRequired,
  fuelDiscountsEnabled: PropTypes.bool.isRequired,
  canCreateDeal: PropTypes.bool.isRequired,
  canUpdateDeal: PropTypes.bool.isRequired,
}

export default ManageDockwaPlusDeals
