import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"
import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"

import { searchContacts } from "src/api/Waitlist/contacts"

import useDebounce from "src/hooks/use_debounce"
import { useDisplayContactOptions } from "src/hooks/use_display_contact_options"

import ContactSearchBoatSelector from "./ContactSearchBoatSelector"
import ContactSection from "./ContactSection"

const getOption = ({ name, email, phone }) => (
  <div className="flex justify-between">
    <span className="flex-1">{name}</span>
    <span className="flex-1">{email}</span>
    <span className="flex-1 text-right">{phone}</span>
  </div>
)

const emptyContactState = {
  name: "",
  email: "",
  phone: "",
}

const emptyContactBoatState = {
  name: "",
  boat_type: "",
  length_overall: "",
  height: "",
  draw: "",
  beam: "",
}

const ContactSearch = ({
  clearable = true,
  showCreateNewOutsideForm = false,
}) => {
  const {
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext()
  const [contactId, contact, contactBoatId] = watch([
    "contact_id",
    "contact",
    "contact_boat_id",
  ])

  const [createNewFlow, setCreateNewFlow] = useState(
    Boolean(
      (!contactId || contactId === "addNew") &&
        (contact?.name || contact?.email || contact?.phone)
    )
  )
  const [searchQuery, setSearchQuery] = useState("")

  const { isFetching, data } = useQuery(
    ["contactSearch", searchQuery],
    () => searchContacts(searchQuery),
    {
      enabled: Boolean(searchQuery),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const {
    getOptions,
    getRenderOption,
    showNoResultsText,
    hasContacts,
    hasArchivedContacts,
  } = useDisplayContactOptions({
    data,
    query: searchQuery,
    renderOption: getOption,
  })

  const clearSelections = useCallback(() => {
    setValue("contact", emptyContactState)
    setValue("contact_boat", emptyContactBoatState)
  }, [setValue])

  useEffect(() => {
    if (contactId !== "addNew" && createNewFlow) {
      // when we toggle back to not adding a new contact,
      // must set create new flow to false and clear out
      // the contact boat id since the contact has changed
      setCreateNewFlow(false)
      setValue("contact_boat_id", "")
    } else if (contactId === "addNew" && !createNewFlow) {
      // otherwise if we toggled to the create new flow we should
      // clear out any current selections
      setCreateNewFlow(true)
      clearSelections()
    }
  }, [contactId, clearSelections, setValue, createNewFlow])

  useEffect(() => {
    if (contactBoatId === "addNew") {
      setValue("contact_boat", emptyContactBoatState)
    }
  }, [contactBoatId, setValue])

  const [debouncedSearch] = useDebounce(setSearchQuery)

  const onSearchInputChanged = (val) => {
    debouncedSearch(val)
  }

  const toggleCreateNew = () => {
    clearErrors("contact_id")
    clearSelections()
    setValue("contact_id", contactId === "addNew" ? null : "addNew")
    setValue("contact_boat_id", contactBoatId === "addNew" ? null : "addNew")
  }

  const hasSelectedContact = Boolean(contactId)
  return (
    <div className="flex flex-col gap-3">
      {!createNewFlow && (
        <div className="w-full">
          <Form.Label htmlFor="lookupContact">Lookup Contact</Form.Label>
          <Controller
            name="contact_id"
            rules={{ required: true }}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <AutocompleteField
                id="lookupContact"
                clearable={clearable}
                onClearCallback={() => {
                  setValue("contact_id", null)
                  setValue("contact", null)
                  setValue("contact_boat_id", null)
                  setValue("contact_boat", null)
                }}
                onSelect={(selectedContact) => {
                  if (hasContacts) {
                    onChange(selectedContact?.id || "")
                    setValue("contact", selectedContact ?? {})
                    setValue("contact_boat_id", "")
                  }
                }}
                selectedItem={contact}
                leadingIcon={<i className="icon icon-search" />}
                placeholder="Search by Name, Email, Phone Number"
                onInputChange={onSearchInputChanged}
                options={getOptions}
                isLoading={isFetching}
                renderOptionsHeader={
                  !hasArchivedContacts && hasContacts
                    ? () =>
                        getOption({
                          name: "Name",
                          email: "Email",
                          phone: "Phone",
                        })
                    : null
                }
                renderOption={({ option }) => getRenderOption(option)}
              />
            )}
          />
          {errors.contact_id?.type === "required" && (
            <Form.Error>Please select a contact</Form.Error>
          )}
          {showNoResultsText && data?.activeContacts?.length === 0 && (
            <div className="my-3 flex items-center justify-between gap-2 p-3 shadow-lg">
              <span>
                No Match Found. Please check your spelling or create a new
                contact.
              </span>
              {!showCreateNewOutsideForm && (
                <Button
                  small
                  variant="primary"
                  type="button"
                  onClick={toggleCreateNew}
                >
                  Create new
                </Button>
              )}
            </div>
          )}
          {showCreateNewOutsideForm && !contactId && (
            <div className="my-2">
              <Button
                small
                variant="ghost"
                type="button"
                onClick={toggleCreateNew}
              >
                Create new contact
              </Button>
            </div>
          )}
        </div>
      )}

      {createNewFlow && (
        <div className="my-2">
          <Button
            small
            variant="secondary"
            type="button"
            icon="icon-arrow-left text-xs"
            onClick={toggleCreateNew}
          >
            Return to contact lookup
          </Button>
        </div>
      )}

      {(hasSelectedContact || createNewFlow) && (
        <>
          <ContactSection isCreateNew={createNewFlow} />
          <Divider />
          <ContactSearchBoatSelector />
        </>
      )}
    </div>
  )
}

ContactSearch.propTypes = {
  clearable: PropTypes.bool,
  showCreateNewOutsideForm: PropTypes.bool,
}

export default ContactSearch
